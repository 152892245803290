import { SearchOrder } from "utils/enums/SearchOrder";
import { TKEvent } from "utils/enums/TKEvent";
import { EventAggregator } from "aurelia-event-aggregator";
import { AppConfig } from "app-config";
import { I18N } from "aurelia-i18n";
import { SearchType } from "utils/enums/SearchType";
import { Utils } from "utils/helpers/utils";
import { IResult } from "domain/IResult";
import { IProduct } from "domain/Product/IProduct";
import { Router } from "aurelia-router";
import { LogManager, autoinject, observable, bindable } from "aurelia-framework";
import { SearchService } from "services/product/search-service";
import { CarHelper } from "utils/helpers/car-helper";

export const log = LogManager.getLogger("app.search.index");

@autoinject
export class Index {
  query: string;
  @observable currentPage = 1;
  type: SearchType;
  @observable orderBy: SearchOrder = SearchOrder.Default;
  @observable orderReversed = false;
  @observable extendedSearch: boolean = false;

  result: IResult<IProduct> | undefined;
  error: string | undefined;
  previousSearch?: string[];

  isQueryVin: boolean;
  isQueryPlate: boolean;
  carQueryType = "";
  carQuery = "";

  // Defaults
  options = {
    showAmount: false,
    showSum: false,
    showAddToCart: true,
    showRemoveFromCart: false,
  };
  defaultResult: IResult<IProduct> = {
    items: [],
    currentPage: 1,
    lastPage: 1,
  };

  constructor(
    private searchService: SearchService,
    private router: Router,
    private i18n: I18N,
    private appConfig: AppConfig,
    private eventAggregator: EventAggregator,
  ) {
    this.eventAggregator.subscribeOnce(TKEvent.userSettingsLoaded, () => {
      if (this.type === undefined) this.type = this.appConfig.userSettings.defaultSearchLimitation;

      this.loadResult();
    });
    this.eventAggregator.subscribe(TKEvent.localeChanged, () => this.loadResult());
  }

  activate(parameters: any) {
    const query = decodeURIComponent(parameters.query);

    if (query.length < 3) {
      this.error = this.i18n.tr("pages.search.errors.short-query");
      return;
    }

    // We want to make sure they are definitely numbers
    const page = parseInt(parameters.page) || 1;
    const type = parseInt(parameters.type) || SearchType.All;
    const orderBy = parseInt(parameters.orderBy) || SearchOrder.Default;
    const orderReversed = parameters.orderReversed == "true";
    const extendedSearch = parameters.extendedSearch == "true";

    // Don't query results twice - a different search has already been initiated
    if (
      query == this.query &&
      page == this.currentPage &&
      type == this.type &&
      orderBy == this.orderBy &&
      orderReversed == this.orderReversed &&
      extendedSearch == this.extendedSearch
    )
      return;

    // Clear the old result so the loading icon could activate again
    this.result = undefined;

    this.query = query;
    this.currentPage = page;
    this.type = type;
    this.orderBy = orderBy;
    this.orderReversed = orderReversed;
    this.extendedSearch = extendedSearch;

    this.loadResult();
  }

  currentPageChanged(newPage: number, oldPage: number) {
    // Required values haven't been set yet
    if (oldPage === undefined || this.type === undefined) return;

    this.search(
      this.query,
      newPage,
      this.type,
      this.orderBy,
      this.orderReversed,
      this.extendedSearch,
    );
  }

  orderByChanged(newOrderBy: SearchOrder, oldOrderBy: SearchOrder) {
    if (oldOrderBy === undefined || this.orderBy === undefined || this.query === undefined) return;

    this.search(
      this.query,
      this.currentPage,
      this.type,
      newOrderBy,
      this.orderReversed,
      this.extendedSearch,
    );
  }

  orderReversedChanged(newOrderReversed: boolean) {
    if (this.query === undefined) return;

    this.search(
      this.query,
      this.currentPage,
      this.type,
      this.orderBy,
      newOrderReversed,
      this.extendedSearch,
    );
  }

  extendedSearchChanged(newExtendedSearch: boolean, oldExtendedSearch: boolean) {
    if (oldExtendedSearch === undefined || this.query === undefined) return;

    this.search(
      this.query,
      this.currentPage,
      this.type,
      this.orderBy,
      this.orderReversed,
      newExtendedSearch,
    );
  }

  loadResult() {
    if (this.query === undefined) return;

    this.result = undefined;
    this.error = undefined;

    // Check search query for VIN or licence plate formats
    this.isQueryVin = CarHelper.vinRegex.test(this.query);
    this.isQueryPlate = CarHelper.carPlateRegex.test(this.query);
    const isPlateStandard = CarHelper.standardCarPlateRegex.test(this.query);

    // Show car search button if the query is in certain format
    if (this.isQueryVin || this.isQueryPlate) {
      this.carQueryType = this.isQueryVin
        ? this.i18n.tr("pages.search.fields.query-type.vin")
        : this.i18n.tr("pages.search.fields.query-type.plate");
      this.carQuery = this.query.toUpperCase();
    }

    const currentSearch = this.getCurrentSearch();
    if (!this.appConfig.userSettings || this.isSameAsPreviousSearch(currentSearch)) return;

    this.makeSearchRequest(isPlateStandard);
  }

  // #region HELPERS

  search(
    query: string,
    page: number,
    type: SearchType,
    orderBy: SearchOrder,
    orderReversed: boolean,
    extendedSearch: boolean,
  ) {
    this.router.navigateToRoute(
      "searchResult",
      {
        query: Utils.encode(query),
        page,
        type,
        orderBy,
        orderReversed,
        extendedSearch,
      },
      {
        trigger: false,
        replace: true,
      },
    );

    this.loadResult();
  }

  makeSearchRequest(isPlateStandard: boolean) {
    this.saveNewPreviousSearch();
    const currentSearch = this.getCurrentSearch();

    this.searchService
      .fetchResult(
        Utils.encode(this.query),
        this.currentPage,
        this.type,
        this.orderBy,
        this.orderReversed,
        this.extendedSearch,
      )
      .then(result => {
        // Don't overwrite new search results
        if (!this.isSameAsPreviousSearch(currentSearch)) return;

        this.result = result ?? this.defaultResult;

        if (this.result.items.length == 0) {
          if (this.extendedSearch != true) {
            this.extendedSearch = true;
            return;
          }

          if (isPlateStandard) {
            // Run the car search automatically when no products were found and query is in standard licence plate format
            Utils.showInfoToast(this.i18n.tr("pages.search.info.no-items-found"));
            this.openCarSearch();
            return;
          }
        }

        this.currentPage = parseInt(result.currentPage as any);
      })
      .catch(error => {
        this.previousSearch = undefined;
        this.error = Utils.getErrorMessage(error, this.i18n);
      });
  }

  openCarSearch() {
    this.router.navigateToRoute("carVehiclesQuery", {
      query: Utils.encode(this.query),
    });
  }

  isSameAsPreviousSearch(currentSearch: string[] | undefined): boolean {
    if (!this.previousSearch || !currentSearch) return false;

    const [prevQuery, prevPage, prevType, prevOrderBy, prevOrderReversed, prevExtendedSearch] =
      this.previousSearch;
    const [thisQuery, thisPage, thisType, thisOrderBy, thisOrderReversed, thisExtendedSearch] =
      currentSearch;

    return (
      prevQuery == thisQuery &&
      prevPage == thisPage &&
      prevType == thisType &&
      prevOrderBy == thisOrderBy &&
      prevOrderReversed == thisOrderReversed &&
      prevExtendedSearch == thisExtendedSearch
    );
  }

  saveNewPreviousSearch() {
    this.previousSearch = this.getCurrentSearch();
  }

  getCurrentSearch() {
    return [
      Utils.encode(this.query),
      this.currentPage.toString(),
      this.type.toString(),
      this.orderBy.toString(),
      this.orderReversed.toString(),
      this.extendedSearch.toString(),
    ];
  }

  // #endregion
}
